import React from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';
import './empaques-sustainibility.scss';

const EmpaquesSustainibility = props => {
  const { isMobile } = useMobileMode();
  const { pageInfo } = props;

  const datalayerLinks = (labelText) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Enlaces',
      category: 'Links',
      action: 'click',
      label: labelText,
      location: location,
      user_data: [],
      log_status: '',
    })
  }

  let options = {
    renderNode : {
      [INLINES.HYPERLINK]: (node, next) => (
        <a
          href={node.data.uri}
          rel="noopener noreferrer"
          onClick={() => datalayerLinks(next[0].props?.children ? next[0].props.children : next[0])}
        >
          {next}
        </a>
      ),
    },
  }

  return (
    <div className="f-empaques-sustainibility">
      <div className="f-empaques-sustainibility-description">
        <h1 className="f-empaques-sustainibility-title">
          {pageInfo.nombreCategoria}
        </h1>

        <div className="f-empaques-sustainibility-text">
          {documentToReactComponents(pageInfo.descripcionGeneral?.json, options)}
        </div>
      </div>

      {pageInfo.imagenPrincipal && (
        <figure className="f-empaques-sustainibility-img">
          <img
            src={
              isMobile
                ? pageInfo.imagenPrincipal[
                    pageInfo.imagenPrincipal.length > 1 ? 1 : 0
                  ].file.url
                : pageInfo.imagenPrincipal[0].file.url
            }
            alt={
              isMobile
                ? pageInfo.imagenPrincipal[
                    pageInfo.imagenPrincipal.length > 1 ? 1 : 0
                  ].description
                : pageInfo.imagenPrincipal[0].description
            }
          />
        </figure>
      )}
    </div>
  );
};

export default EmpaquesSustainibility;
