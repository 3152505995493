import React, { useEffect } from 'react';
import { siteUrl } from '../../../shared/utils/siteUrl';
import useMobileMode from '../../../hooks/useMobileMode';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import ReactPlayer from 'react-player/lazy';
import Slider from 'react-slick';
import { removeDoubleSlash } from '../../../shared/utils/urlUtil';
import {
  pushDataLayerEvent,
  pushGtagEvent,
} from '../../../shared/utils/metricManagementUtil';
import './fibras-sustainibility.scss';

const FibrasSustainibility = (props) => {
  const { isMobile } = useMobileMode();
  const { pageInfo } = props;

  const gTagViewItemList = (products) => {
    const items = products.map((product, index) => {
      const { productName, category, commercialPartner } = product;
      return {
        item_id: '',
        item_name: productName?.productName || '',
        coupon: 'Ninguno',
        discount: '0',
        index: index,
        item_list_name: category?.title.title || '',
        item_list_id: '',
        affiliation: commercialPartner || '',
        item_brand: 'Familia',
        item_category: 'Productos',
        item_variant: '',
        price: '0',
        currency: 'COP',
        quantity: '0',
      };
    });

    pushGtagEvent({
      event: 'view_item_list',
      params: {
        ecommerce: {
          items: items,
        },
        item_list_name: pageInfo.tituloSecundario,
        item_list_id: '',
        user_data: [],
        log_status: '',
      },
    });
  };
  useEffect(() => {
    gTagViewItemList(pageInfo.referenciasProductos);
  }, []);

  const datalayerVideo = (videoName, durationVideo) => {
    let location = '';
    if (typeof window !== 'undefined') {
      location = window.location.href;
    }
    pushDataLayerEvent({
      event: 'Interacciones_Video',
      category: 'Videos',
      action: 'click',
      label: 'video',
      video_name: videoName,
      duration_video: durationVideo+'s',
      location: location,
      user_data: [],
      log_status: '',
    });
  };

  const gTagSelectItem = (item, index) => {
    let location = '';
    if (typeof window !== 'undefined') {
      location = window.location.href;
    }
    const { productName, category, commercialPartner, slug } = item;
    pushGtagEvent({
      event: 'select_item',
      params: {
        ecommerce: {
          items: [
            {
            item_id: '',
            item_name: productName?.productName||'',
            coupon: 'Ninguno',
            discount: '0',
            index: index,
            item_list_name: category?.title.title||'',
            item_list_id: category?.id||'',
            affiliation: commercialPartner ? commercialPartner : '',
            item_brand: 'Familia',
            item_category: 'Productos',
            item_variant: 'Ninguno',
            price: '0',
            currency: 'COP',
            quantity: '0',
            },
          ],
        },
        item_list_name: category?.title.title||'',
        item_list_id: category?.id||'',
        units: '0',
        qualification: '0',
        user_data: [],
        log_status: ''
      },
    });
    pushDataLayerEvent({
      event: 'product_click',
      single_item_id: '',
      single_item_name: category?.title.title + ' ' +productName?.productName,
      link_text: category?.title.title,
      link_url: location,
    });
  };

  let initialTime = 0;
  let isPlaying = false;

  const onPlayVideo = () => {
    if (typeof window !== 'undefined') {
      if (!isPlaying) {
        initialTime = window.performance.now();
      }
    }
  };

  const onPauseVideo = (videoName) => {
    if (typeof window !== 'undefined') {
      const viewDurationVideo = (window.performance.now() - initialTime) / 1000;
      datalayerVideo(videoName, viewDurationVideo);
    }
  };

  const datalayerLinks = (labelText) => {
    let location = '';
    if (typeof window !== 'undefined') {
      location = window.location.href;
    }
    pushDataLayerEvent({
      event: 'Interacciones_Enlaces',
      category: 'Links',
      action: 'click',
      label: labelText,
      location: location,
      user_data: [],
      log_status: '',
    });
  };

  let options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        if (node.data.target.fields) {
          if (
            node.data.target.fields.file['es-CO'].contentType
              ?.toLowerCase()
              .includes('video')
          ) {
            return (
              <div className="f-video-wrapper">
                <ReactPlayer
                  url={node.data.target.fields.file['es-CO'].url}
                  height="100%"
                  width="100%"
                  playing={false}
                  pip={true}
                  controls={true}
                  onPlay={() => onPlayVideo()}
                  onPause={() =>
                    onPauseVideo(
                      node.data.target.fields.file['es-CO'].fileName || ''
                    )
                  }
                />
              </div>
            );
          } else {
            return (
              <img
                className="f-image"
                src={node.data.target.fields.file['es-CO'].url}
                alt={node.data.target.fields.title['es-CO']}
              />
            );
          }
        } else {
          return null;
        }
      },
      [INLINES.HYPERLINK]: (node, next) => (
        <a
          href={node.data.uri}
          rel="noopener noreferrer"
          onClick={() =>
            datalayerLinks(
              next[0].props?.children ? next[0].props.children : next[0]
            )
          }
        >
          {next}
        </a>
      ),
    },
  };

  var settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    focusOnSelect: true,
    dots: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="f-fibras-sustainibility">
      {pageInfo.descripcionGeneral && (
        <div className="f-sustainibility-general-description">
          {documentToReactComponents(pageInfo.descripcionGeneral.json, options)}
        </div>
      )}

      {pageInfo.referenciasGenerales && (
        <div className="f-sustainibility-referencias-content">
          {pageInfo.referenciasGenerales.map((referencia, index) => {
            return (
              <div className="f-sustainibility-referencia" key={index}>
                <figure className="f-sustainibility-referencia-image">
                  <img
                    src={
                      isMobile
                        ? referencia.image[referencia.image.length > 1 ? 1 : 0]
                            .file.url
                        : referencia.image[0].file.url
                    }
                    alt={
                      isMobile
                        ? referencia.image[referencia.image.length > 1 ? 1 : 0]
                            .description
                        : referencia.image[0].description
                    }
                  />
                </figure>

                {documentToReactComponents(
                  referencia?.descripcionTextoEnriquecido?.json,
                  options
                )}
              </div>
            );
          })}
        </div>
      )}

      {pageInfo.tituloSecundario && (
        <h2 className="f-sustainibility-secondary-title">
          {pageInfo.tituloSecundario}
        </h2>
      )}

      {pageInfo.referenciasProductos && (
        <div className="f-sustainibility-products-container">
          {/* <div className="f-sustainibility-products-grid"> */}
          <Slider {...settings} className="f-sustainibility-products-slider">
            {pageInfo.referenciasProductos.map((product, index) => {
              return (
                <a
                  href={
                    siteUrl +
                    removeDoubleSlash(
                      '/productos/' + product?.category?.slug + product?.slug
                    )
                  }
                  onClick={() => {
                    gTagSelectItem(product, index);
                  }}
                  className="f-sustainibility-product"
                  key={index}
                >
                  <figure className="f-sustainibility-product-image">
                    <img
                      src={
                        isMobile
                          ? product.image[product.image.length > 1 ? 1 : 0].file
                              .url
                          : product.image[0].file.url
                      }
                      alt={
                        isMobile
                          ? product.image[product.image.length > 1 ? 1 : 0]
                              .description
                          : product.image[0].description
                      }
                    />
                  </figure>
                  <p className="f-sustainibility-product-category">
                    {product.category.title.title}
                  </p>
                  <h3 className="f-sustainibility-product-title">
                    {product.productName?.productName}
                  </h3>
                </a>
              );
            })}
          </Slider>
          {/* </div> */}
        </div>
      )}

      {pageInfo.descripcionSecundaria && (
        <div className="f-sustainibility-secondary-description-container">
          <div className="f-sustainibility-secondary-description">
            {documentToReactComponents(
              pageInfo.descripcionSecundaria.json,
              options
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FibrasSustainibility;
