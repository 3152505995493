import Fab from '@mui/material/Fab';
import { navigate } from 'gatsby';
import React from 'react';
import './buttons-product.scss';
import ShopModal from '../../atoms/shop-modal/shop-modal';
import { removeDoubleSlash } from '../../../shared/utils/urlUtil';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const CUSTOM_SHOP_MODAL = ['1yZtTBnkfedwyadpWSkUsp', '7yzh3ZMaSXwS9Ndz8XANAI'];

const ButtonsProducts = (props) => {
  const { setmodalActive } = props;

  const {
    slug,
    category,
    commercialPartner,
    secondCommercialPartner,
    thirdCommercialPartner,
    contentful_id = '',
    productName,
  } = props.productData;
  const LOCALE = process.env.LOCALE;
  const isLocalShop =
    LOCALE === 'es-EC' && CUSTOM_SHOP_MODAL.includes(contentful_id);

  const productToDisableButton = 'papel-higienico-familia-triplemax/';

  const redirectQualify = () => {
    navigate(
      removeDoubleSlash('/productos/' + category.slug + slug + 'calificar')
    );
  };

  // const handleRedirectRebaja = () => {
  //   // TODO: Esto debe llegar desde contenido, al igual que las urls de las imagenes de abajo
  //   // window.location.href = 'https://www.larebajavirtual.com/catalogo/buscar?subMenuCategory=on&busqueda=' + slug;
  //   window.open(commercialPartner);
  // };

  const commercialPartners = [
    commercialPartner,
    secondCommercialPartner,
    thirdCommercialPartner,
  ];

  const handleShowModal = () => {
    setmodalActive(
      <ShopModal
        setmodalActive={setmodalActive}
        commercialPartners={commercialPartners}
        isLocalShop={isLocalShop}
        productData={props.productData}
      />
    );
  };

  const datalayerBuyButton = () => {
    let location = '';
    if (typeof window !== 'undefined') {
      location = window.location.href;
    }
    pushDataLayerEvent({
      event: 'Interacciones_Producto',
      category: 'Productos',
      action: 'click',
      label: 'Comprar',
      location: location,
      user_data: [],
      log_status: '',
    });
    pushDataLayerEvent({
      event: 'buy_now_list_retailers',
      single_item_id: contentful_id,
    });
  };

  const datalayerQualifyButton = (labelText) => {
    let location = '';
    if (typeof window !== 'undefined') {
      location = window.location.href;
    }
    pushDataLayerEvent({
      event: 'Interacciones_Producto',
      category: 'Productos',
      action: 'click',
      label: 'Calificar',
      item_name: labelText,
      location: location,
      user_data: [],
      log_status: '',
    });
    pushDataLayerEvent({
      event: 'review',
      action_type: 'Submit a review',
      single_item_id: contentful_id,
      single_item_name: labelText,
    });
  };

  const datalayerPrintModal = (labelText) => {
    let location = '';
    if (typeof window !== 'undefined') {
      location = window.location.href;
    }
    pushDataLayerEvent({
      event: 'pop_up',
      action_type: 'print',
      label: 'Tiendas aliadas',
      single_item_id: contentful_id,
      single_item_name: labelText,
      link_text: 'Comprar',
      link_url: location,
      link_domain: 'familia.com.co',
    });
  };
  return (
    <div className="f-product-description-buttons">
      {!(slug === productToDisableButton && LOCALE === 'es-EC') && (
        <Fab
          variant="extended"
          size="large"
          aria-label="add"
          className="f-item-redirect-button-pay"
          // onClick={handleRedirectRebaja}
          onClick={() => {
            datalayerBuyButton();
            handleShowModal();
            datalayerPrintModal(
              category.title?.title + ' ' + productName?.productName
            );
          }}
        >
          <i className="far fa-shopping-cart f-cart-shop"></i>
          COMPRAR
          {/* <img
          className=""
          src="//images.ctfassets.net/tcoigcjw85h2/6cqL5i11qX2tUehNm6Mh81/b39c5680039856789a0d4c51a522a184/logo_la_rebaja2.png"
          alt="imagen"
        ></img> */}
        </Fab>
      )}

      <a
        href={removeDoubleSlash(`/productos/${category.slug}${slug}calificar`)}
        className={'nostyle'}
        onClick={() =>
          datalayerQualifyButton(
            category.title?.title + ' ' + productName?.productName
          )
        }
      >
        <Fab
          variant="extended"
          size="large"
          aria-label="add"
          className="f-item-redirect-button-qualify"
          onClick={redirectQualify}
        >
          CALIFICAR
          <i className="fas fa-star f-star-qualify"></i>
        </Fab>
      </a>

      {category && category.slug === 'papel-higienico' && (
        <a href={`/calculadora-papel-higienico`} className={'nostyle'}>
          <Fab
            variant="extended"
            size="large"
            aria-label="add"
            className="f-item-redirect-button-famicalculadora"
          >
            <i class="fas fa-2x fa-calculator fa-calculator-quality"></i>
            FAMICALCULADORA
          </Fab>
        </a>
      )}
    </div>
  );
};

export default ButtonsProducts;
