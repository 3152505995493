import React, { useState } from 'react';
import SEO from '../../../shared/components/seo';
import Layout from '../../organisms/layout/layout';
import SustainibilityPagesContent from '../../organisms/sustainibility-pages-content/sustainibility-pages-content';
import './sustainibility-template.scss';

export default props => {

  const {pageInfo, ...params} = props.pageContext;
  const [modalActive, setmodalActive] = useState(null);

  return (
    <>
      <SEO
        title={pageInfo.metaTitulo || params.metaTitle || ''}
        description={
          pageInfo.metaDescripcion?.metaDescripcion || params.metaDescription?.metaDescription || ''
        }
        slug={'/nosotros/sostenibilidad/' + pageInfo.slug}
      />
      <Layout
        menuActive={1}
        setmodalActive={setmodalActive}
        modalActive={modalActive}
        title={pageInfo.metaTitulo || pageInfo.tituloSecundario || 'FAMILIA'}
        slug={pageInfo.slug}
      >
        <SustainibilityPagesContent
          pageInfo={pageInfo}
          menuInfo={params.menuInfo}
          setmodalActive={setmodalActive}
        />
      </Layout>
    </>
  );
};
