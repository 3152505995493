import React, { useRef, useState, useEffect } from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import Slider from 'react-slick';
import './huella-sustainibility.scss';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const HuellaSustainibility = props => {
  const { isMobile } = useMobileMode();
  const { pageInfo } = props;

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [sliderMobileHeigth, setSliderMobileHeigth] = useState(null);

  const contenedorSlider2 = useRef(null);

  var slider1;
  var slider2;

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
    return;
  });

  useEffect(() => {
    if (isMobile) {
      setSliderMobileHeigth(parseInt(contenedorSlider2.current.clientHeight));
    }
  });

  var settings1 = {
    slidesToShow: isMobile
      ? parseInt(sliderMobileHeigth / 50) - 1
      : pageInfo?.imagenPrincipal.length > 6
      ? 6
      : pageInfo?.imagenPrincipal.length,
    focusOnSelect: true,
    arrows: false,
    vertical: isMobile,
    verticalSwiping: isMobile,
  };

  var settings2 = {
    infinite: true,
    slidesToShow: 1,
    arrows: false,
  };

  const datalayerLinks = (labelText) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Enlaces',
      category: 'Links',
      action: 'click',
      label: labelText,
      location: location,
      user_data: [],
      log_status: '',
    })
  }

  let options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, next) =>  <p className="text">{next}</p>,
        [INLINES.HYPERLINK]: (node, next) => (
          <a
              href={node.data.uri}
              rel="noopener noreferrer"
              target={node.data.uri.includes("http")
                      ? "_blank"
                      : "_self"
              }
              onClick={() => datalayerLinks(next[0].props?.children ? next[0].props.children : next[0])}
          >
              {next}
          </a>
      ),
    }
}
  return (
    <div className="f-huella-sustainibility">
      {pageInfo.descripcionGeneral && (
        <div className="f-huella-general-description">
          {documentToReactComponents(pageInfo.descripcionGeneral.json, options)}
        </div>
      )}

      <div className="f-huella-sliders-container">
        <Slider
          {...settings1}
          ref={slider => (slider1 = slider)}
          className="f-prev-imgs-huella-slider"
          asNavFor={nav2}
          style={{ height: sliderMobileHeigth }}
        >
          {pageInfo?.imagenPrincipal.map((imagen, index) => {
            return (
              <div className="f-prev-img-container f-two-items" key={index}>
                <img
                  className="f-src-img"
                  src={imagen.file.url}
                  alt={imagen.description}
                />
              </div>
            );
          })}
        </Slider>
        <div className="f-huella-slider">
          <Slider
            {...settings2}
            ref={slider => (slider2 = slider)}
            className="f-active-imgs-huella-slider"
            asNavFor={nav1}
          >
            {pageInfo?.imagenPrincipal.map((imagen, index) => {
              return (
                <div
                  className="f-active-img-container"
                  key={index}
                  ref={contenedorSlider2}
                >
                  <img
                    className="f-active-src-img"
                    src={imagen.file.url}
                    alt={imagen.description}
                  />
                </div>
              );
            })}
          </Slider>

          <div className="f-next-prev-container">
            <div
              className="f-btn-huella-slide f-prev-btn"
              onClick={() => {
                nav1.slickPrev();
              }}
            >
              <span className="fas fa-caret-left" />
            </div>
            <div
              className="f-btn-huella-slide f-next-btn"
              onClick={() => {
                nav1.slickNext();
              }}
            >
              <span className="fas fa-caret-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HuellaSustainibility;
