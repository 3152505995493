import React from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import ReactPlayer from 'react-player/lazy';
import ButtonsProduct from '../../molecules/buttons-product/buttons-product';
import './ovejas-sustainibility.scss';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const OvejasSustainibility = props => {
  const { isMobile } = useMobileMode();
  const { pageInfo, setmodalActive } = props;

  const datalayerLinks = (labelText) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Enlaces',
      category: 'Links',
      action: 'click',
      label: labelText,
      location: location,
      user_data: [],
      log_status: '',
    })
  }

  let options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children && children[0]?.trim() !== '') {
          return <p className="text">{children}</p>;
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        if (node.data.target.fields) {
          if (
            node.data.target.fields.file['es-CO'].contentType
              ?.toLowerCase()
              .includes('video')
          ) {
            return (
              <div className="f-video-wrapper">
                <ReactPlayer
                  url={node.data.target.fields.file['es-CO'].url}
                  height="100%"
                  width="100%"
                  playing={false}
                  pip={true}
                  controls={true}
                />
              </div>
            );
          } else {
            return (
              <img
                className="f-image"
                src={node.data.target.fields.file['es-CO'].url}
                alt={node.data.target.fields.title['es-CO']}
              />
            );
          }
        } else {
          return null;
        }
      },
      [INLINES.HYPERLINK]: (node, next) => (
        <a href={node.data.uri} rel="noopener noreferrer" target="_blank" 
        onClick={() => datalayerLinks(next[0].props?.children ? next[0].props.children : next[0])}>
          {next}
        </a>
      ),
    },
  };

  return (
    <div className="f-ovejas-sustainibility">
      {pageInfo.descripcionGeneral && (
        <div className="f-ovejas-general-description">
          {documentToReactComponents(pageInfo.descripcionGeneral.json, options)}

          {pageInfo.referenciasProductos[0] && (
            <ButtonsProduct
              setmodalActive={setmodalActive}
              productData={pageInfo.referenciasProductos[0]}
            ></ButtonsProduct>
          )}
        </div>
      )}

      {pageInfo.tituloSecundario && (
        <div className="f-ovejas-secondary-title-container">
          <h2 className="f-ovejas-secondary-title">
            {pageInfo.tituloSecundario}
          </h2>
        </div>
      )}

      <div className="f-ovejas-secondary-description-container">
        {pageInfo.imagenSecundaria && (
          <div className="f-ovejas-secondary-video">
            <ReactPlayer
              url={pageInfo.urlVideoYoutube}
              height="100%"
              width="100%"
              playing={false}
              pip={true}
              controls={true}
            />
          </div>
          // <img
          //   className="f-ovejas-secondary-image"
          //   src={
          //     isMobile
          //       ? pageInfo.imagenSecundaria[
          //           pageInfo.imagenSecundaria.length > 1 ? 1 : 0
          //         ].file.url
          //       : pageInfo.imagenSecundaria[0].file.url
          //   }
          //   alt={
          //     isMobile
          //       ? pageInfo.imagenSecundaria[
          //           pageInfo.imagenSecundaria.length > 1 ? 1 : 0
          //         ].description
          //       : pageInfo.imagenSecundaria[0].description
          //   }
          // />
        )}

        {pageInfo.descripcionSecundaria && (
          <div className="f-ovejas-secondary-description">
            {documentToReactComponents(
              pageInfo.descripcionSecundaria.json,
              options
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OvejasSustainibility;
