import React from 'react';
import HeaderSostainibility from '../../molecules/header-sostainibility/header-sostainibility';
import './sustainibility-pages-content.scss';
import FibrasSustainibility from '../../molecules/fibras-sustainibility/fibras-sustainibility';
import EmpaquesSustainibility from '../../molecules/empaques-sustainibility/empaques-sustainibility';
import HuellaSustainibility from '../../molecules/huella-sustainibility/huella-sustainibility';
import OvejasSustainibility from '../../molecules/ovejas-sustainibility/ovejas-sustainibility';

const SustainibilityPagesContent = props => {
  const { pageInfo, menuInfo , setmodalActive} = props;
  const namePage =
    pageInfo?.nombreCategoria?.toLowerCase() + pageInfo?.slug?.toLowerCase();

  return (
    <div className="f-sustainibility-pages-container">
      <HeaderSostainibility
        banner={pageInfo.bannerPrincipal}
        menuInfo={menuInfo}
        activeOption={pageInfo.nombreCategoria}
      />

      <div className="f-sustainibility-content">
        {namePage.includes('fibras') ? (
          <FibrasSustainibility pageInfo={pageInfo} />
        ) : namePage.includes('empaques') ? (
          <EmpaquesSustainibility pageInfo={pageInfo} />
        ) : namePage.includes('huella') ? (
          <HuellaSustainibility pageInfo={pageInfo} />
        ) : namePage.includes('ovejas') || namePage.includes('green') ? (
          <OvejasSustainibility pageInfo={pageInfo} setmodalActive={setmodalActive}/>
        ) : (
          <div>Esta página no existe</div>
        )}
      </div>
    </div>
  );
};

export default SustainibilityPagesContent;
